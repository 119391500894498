<template>
  <div v-if="isShow" class="flex fixed top-0 left-0 justify-center w-full h-full z-50">
    <div class="flex min-h-screen min-w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-grey-dark opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"> &#8203; </span>
      <div
        class="inline-block align-bottom bg-white dark:bg-dark-bgSecondary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div class="flex flex-col">
            <div class="relative bg-magenta py-2">
              <span class="absolute right-2 top-2 cursor-pointer text-black-1" @click="$emit('onClose')">
                <CloseIcon class="h-5 w-5 md:h-7 md:w-7" />
              </span>
              <h3 class="text-lg leading-6 text-white ml-3 font-montserrat font-semibold" id="modal-headline">CSS help</h3>
            </div>
            <div class="text-center sm:text-left text-gray-900 dark:text-dark-textPrimary">
              <div class="sm:w-full">
                <pre class="overflow-y-auto max-h-screen-2/3">
                <code class="language-css">
                  {{getCssHelp}}
                </code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@Assets/images/CloseIcon";
import { insertJs } from "@/utils";
export default {
  name: "CSSHelpPopup",
  components: {
    CloseIcon,
  },
  props: {
    isShow: {
      type: Boolean,
      requried: true,
    },
  },
  data: () => ({
    isInited: false,
  }),
  computed: {
    getCssHelp() {
      return this.$store.getters.getCssHelper;
    },
  },
  watch: {
    isShow(value) {
      if (value) {
        this.$nextTick(() => {
          window.Prism.highlightAll();
        });
      }
    },
  },
  created() {
    insertJs("script", ["/external/prismjs/prism.js"], () => {
      insertJs("link", ["/external/prismjs/prism.css"], () => (this.isInited = true));
    });
  },
};
</script>
