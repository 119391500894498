<template>
  <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5 mb-8 md:mb-0" @submit="onSubmit">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Name: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.name"
          name="name"
          type="text"
          :class="{
            'text-danger border border-danger': errors['name'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["name"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Login Mode: </label>
      </div>
      <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
        <ComboBox
          class="w-full"
          v-model="data.loginMode"
          :options="[
            { label: 'Public', value: 'PUBLIC' },
            { label: 'Pre-registered Viewer Logins', value: 'CUSTOM_LIST' },
          ]"
        />
      </div>
    </div>

    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Allow anonymous login: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isAnonymousLogin" class="mb-2 self-start" />
      </div>
    </div>

    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Password Protection: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isProtect" class="mb-2 self-start" />
        <template v-if="settings.isProtect">
          <Field
            v-model="settings.password"
            type="text"
            name="password"
            rows="5"
            :class="{
              'text-danger border border-danger': errors['password'],
            }"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["password"] }}
          </p>
        </template>
      </div>
    </div>

    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Paid event: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="data.isPaidEvent" class="mb-2 self-start" />
        <template v-if="data.isPaidEvent">
          <Field
            v-model="data.price"
            type="text"
            name="price"
            rows="5"
            :class="{
              'text-danger border border-danger': errors['price'],
            }"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["price"] }}
          </p>
        </template>
      </div>
    </div>

    <button
      type="submit"
      class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-4 md:-right-4 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { computed, reactive } from "vue";

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import * as _ from "lodash";

import { sleep } from "@Helpers/sleep";
import { putEventTemplate } from "@API/event/template-repository";
import Switch from "@/components/layouts/Switch.vue";
import ComboBox from "@/components/layouts/ComboBox";
import { get } from "lodash";

export default {
  name: "GeneralTab",
  props: {
    eventTemplateDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    Switch,
    ComboBox,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const currentEvent = computed(() => props.eventTemplateDetail);

    const settings = reactive({
      isProtect: !!get(currentEvent.value, "settings.isProtect"),
      password: get(currentEvent.value, "settings.password", ""),
      isAnonymousLogin: get(currentEvent.value, "settings.isAnonymousLogin", false),
    });
    const data = reactive({
      loginMode: currentEvent.value.loginMode ?? "PUBLIC",
      isPaidEvent: currentEvent.value.isPaidEvent ?? false,
      price: currentEvent.value.isPaidEvent ? currentEvent.value.price ?? 0 : 0,
    });

    const schema = Yup.object().shape({
      name: Yup.string().required("Event name is required"),
      password: Yup.lazy(() => (settings.isProtect ? Yup.string().required("password is required") : Yup.string())),
      price: Yup.lazy(() => (data.isPaidEvent ? Yup.string().required("Price is required") : Yup.string())),
    });

    return {
      currentEvent,
      data,
      schema,
      settings,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.$store.dispatch("updateLoadingText", "Updating event");
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          settings: {
            ...this.currentEvent.settings,
            ...this.settings,
          },
          loginMode: this.data.loginMode,
          price: this.data.isPaidEvent ? this.data.price : 0,
          isPaidEvent: this.data.isPaidEvent,
        };
        _.forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " ")) delete payload[key];
        });
        const result = await putEventTemplate(payload);
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
