<template>
  <section class="event-detail relative">
    <h3 v-if="empty(data.eventTemplateDetail)" class="text-center text-magenta">
      <template v-if="data.invalidEvent"> Event template invalid </template>
    </h3>
    <template v-else>
      <div class="block md:flex justify-between">
        <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">
          {{ data.eventTemplateDetail.name }}
        </h1>
      </div>

      <div class="flex flex-wrap mt-5 lg:mt-3">
        <div class="w-full">
          <ul class="flex flex-row list-none md:flex-wrap border-b-3 border-grey-light overflow-x-scroll overflow-y-hidden md:overflow-visible">
            <li class="-mb-3px pr-2" v-for="(item, index) in data.dataSource" :key="index">
              <a
                :class="[data.openTabNumber === index ? activeClass : inactiveClass]"
                class="text-base font-bold uppercase block leading-normal cursor-pointer p-1.5 md:px-2 lg:px-6 md:pb-4 pl-0"
                @click="toggleTabs(index)"
              >
                {{ item.title }}
              </a>
            </li>
          </ul>

          <div class="flex flex-col min-w-0 break-words w-full">
            <div class="flex-auto dark:text-dark-textPrimary pt-4">
              <div v-for="(item, index) in data.dataSource" :key="index" :class="[data.openTabNumber !== index ? 'hidden' : 'block']">
                <component
                  :is="item.component"
                  :isActive="data.openTabNumber === index"
                  :eventTemplateDetail="data.eventTemplateDetail"
                  @onUpdate="onUpdateEventTemplate"
                  @toggleLoading="onToggleLoading($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { ref, reactive, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralTab from "@Components/pages/event-template/GeneralTab";
import PrepareTab from "@Components/pages/event-template/PrepareTab";
import DesignTab from "@Components/pages/event-template/DesignTab";
import { filter, find } from "lodash";
import { empty } from "@/utils";
import { useTableData } from "@Libs/hooks/useDataTable";

export default {
  name: "EventTemplateDetail",
  components: {
    GeneralTab,
    PrepareTab,
    DesignTab,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const data = reactive({
      openTabNumber: 0,
      dataSource: [
        {
          title: "General",
          component: "GeneralTab",
        },
        {
          title: "Design",
          component: "DesignTab",
        },
        {
          title: "Pre/posts views",
          component: "PrepareTab",
        },
      ],
      eventTemplateDetail: {},
      invalidEvent: false,
    });
    data.dataSource = filter(data.dataSource, item => item !== false);

    const activeClass = ref("text-blue-light dark:text-dark-textAccent border-b-3 border-blue-light dark:border-dark-textAccent");
    const inactiveClass = ref("text-grey-mid");
    const doGetEventTemplate = async (isForce = true) => {
      try {
        if (isForce) {
          store.dispatch("toggleLoading");
        }
        data.invalidEvent = false;
        const { fetchEventTemplatesListDataTable } = useTableData();
        const eventTemplateList = await fetchEventTemplatesListDataTable("", "asc");
        let eventTemplateDetail = find(eventTemplateList, item => item.id === route.params.templateId);
        eventTemplateDetail = {
          ...eventTemplateDetail,
        };
        data.eventTemplateDetail = eventTemplateDetail;

        data.invalidEvent = empty(data.eventTemplateDetail);
      } catch (error) {
        console.log({ error });
        data.invalidEvent = true;
      } finally {
        if (isForce) {
          store.dispatch("closeLoading");
        }
      }
    };

    onBeforeMount(doGetEventTemplate);

    function toggleTabs(tabNumber) {
      data.openTabNumber = tabNumber;
    }

    function onUpdateEventTemplate() {
      doGetEventTemplate(false);
    }

    function onToggleLoading(isLoading = true) {
      if (isLoading) {
        store.dispatch("toggleLoading");
      } else {
        store.dispatch("closeLoading");
      }
    }

    return {
      data,
      activeClass,
      inactiveClass,
      toggleTabs,
      doGetEventTemplate,
      empty,
      onUpdateEventTemplate,
      onToggleLoading,
    };
  },
};
</script>
