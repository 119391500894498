<template>
  <Form class="flex flex-col font-fs-elliot-pro py-3 md:py-7 lg:py-5 mb-8 md:mb-0" @submit="onSubmit">
    <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
      <div class="w-full md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Primary color: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <div class="relative flex items-center">
          <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ currentEvent.primaryColour.substring(1) }}</span>

          <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

          <Field
            v-model="currentEvent.primaryColour"
            name="primaryColor"
            type="color"
            class="
              h-12
              w-52
              md:w-60
              text-lg text-left
              font-semibold
              appearance-none
              bg-off-white-1
              dark:bg-dark-bgPrimary
              text-grey-dark
              dark:text-dark-textPrimary
              border border-grey-mid
              focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
              rounded
              p-0.5
              pl-11.75
              pr-1
            "
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
      <div class="w-full md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Secondary color: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <div class="relative flex items-center">
          <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ currentEvent.secondaryColor.substring(1) }}</span>

          <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

          <Field
            v-model="currentEvent.secondaryColor"
            name="secondaryColor"
            type="color"
            class="
              h-12
              w-52
              md:w-60
              text-lg text-left
              font-semibold
              appearance-none
              bg-off-white-1
              dark:bg-dark-bgPrimary
              text-grey-dark
              dark:text-dark-textPrimary
              border border-grey-mid
              focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
              rounded
              p-0.5
              pl-11.75
              pr-1
            "
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
      <div class="w-full md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Font: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 lg:w-2/3">
        <ComboBox
          class="w-full"
          v-model="data.currentFont"
          :options="[
            { label: 'Mulish', value: `'Mulish', sans-serif` },
            { label: 'Inconsolata', value: `'Inconsolata', monospace` },
            { label: 'Montserrat', value: `'Montserrat', sans-serif` },
            { label: 'Noto Sans Mono', value: `'Noto Sans Mono', monospace` },
            { label: 'Poppins', value: `'Poppins', sans-serif` },
            { label: 'Roboto', value: `'Roboto', sans-serif` },
            { label: 'Local font', value: '' },
            {
              label: 'Open Sans',
              value: `'Open Sans', sans-serif`,
            },
          ]"
        />
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Custom css: </label>
      </div>
      <div class="w-full md:w-2/3 mt-2 md:mt-0 flex flex-col">
        <Field
          v-model="currentEvent.customCSS"
          as="textarea"
          name="customCSS"
          rows="5"
          class="
            w-full
            text-xs
            md:text-base
            text-black-1
            dark:text-dark-textPrimary
            bg-off-white-1
            dark:bg-dark-bgPrimary
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            rounded
            p-2
            md:p-3
          "
        />
        <a href="javascript:void(0)" class="mt-2 text-magenta underline" @click="data.showCssHelp = true">Css help</a>
      </div>
      <Popup :isShow="data.showCssHelp" @onClose="data.showCssHelp = false" />
    </div>

    <div class="flex mb-7 lg:mb-8">
      <div class="md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Ticker Bar Message: </label>
      </div>
      <div class="ml-4 md:ml-0 md:w-2/3 mt-2 md:mt-0">
        <Switch v-model="data.isShowTicker" class="mb-2 self-start" />
      </div>
    </div>
    <template v-if="data.isShowTicker">
      <div class="flex items-center mb-7 lg:mb-8">
        <div class="md:w-1/3 lg:w-1/4">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Ticker Bar: </label>
        </div>
        <div class="w-full md:w-2/3 mt-2 md:mt-0">
          <ComboBox
            class="w-full"
            v-model="data.eventMode"
            :options="[
              { label: 'Message', value: 'MESSAGE' },
              { label: 'Isi API Data', value: 'DONATE' },
            ]"
          />
        </div>
      </div>
      <div v-if="data.eventMode === 'MESSAGE'" class="flex items-center mb-7 lg:mb-8">
        <div class="md:w-1/3 lg:w-1/4">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
        </div>
        <div class="w-full md:w-2/3 mt-2 md:mt-0">
          <Field
            name="eventModeMessage"
            v-model="currentEvent.eventModeMessage"
            as="textarea"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
        </div>
      </div>
    </template>

    <button
      type="submit"
      class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-4 md:-right-4 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { ref, computed, reactive } from "vue";
import { Form, Field } from "vee-validate";
import { TweenMax } from "gsap";
import { forOwn, get } from "lodash";
import axios from "axios";

import { sleep } from "@Helpers/sleep";
import { putEventTemplate } from "@API/event/template-repository";

import ComboBox from "@/components/layouts/ComboBox";
import Popup from "./Popup";
import Switch from "@/components/layouts/Switch.vue";

import { empty, isFile } from "@/utils";

export default {
  name: "DesignTab",
  props: {
    eventTemplateDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    Switch,
    ComboBox,
    Popup,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const data = reactive({
      currentFont: props.eventTemplateDetail.font ?? "",
      eventMode: props.eventTemplateDetail.eventMode ?? "MESSAGE",
      isShowTicker: get(props.eventTemplateDetail, "settings.bottomBar") === "SHOW",
      showCssHelp: false,
    });

    const currentEvent = computed(() => props.eventTemplateDetail);

    return {
      data,
      currentEvent,
    };
  },

  methods: {
    empty,

    async onSubmit() {
      try {
        this.$store.dispatch("updateLoadingText", "Preparing");
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          font: this.data.currentFont,
          // eventMode: this.data.eventMode,
          eventMode: "MESSAGE",
          settings: {
            ...get(this.currentEvent, "settings", {}),
            bottomBar: this.data.isShowTicker ? "SHOW" : "HIDE",
          },
        };

        forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " ")) delete payload[key];
        });

        this.$store.dispatch("updateLoadingText", "Updating event");
        const result = await putEventTemplate(payload);

        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>

